var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { staticClass: "wrapper layout_default" },
    [
      _c(
        "el-aside",
        {
          staticClass: "main_aside",
          class: {
            active: _vm.extend_switch && _vm.open_extend,
            no_sidebar: _vm.$route.meta.hideSidebar,
          },
          nativeOn: {
            mouseover: function ($event) {
              return _vm.mainAsideMouseOverHandler()
            },
            mouseout: function ($event) {
              return _vm.mainAsideMouseOutHandler()
            },
          },
        },
        [
          _c(
            "el-container",
            { staticClass: "fun_aside" },
            [
              _c(
                "el-aside",
                { staticClass: "fun_main", attrs: { width: "60px" } },
                [_c("fun-aside")],
                1
              ),
              !_vm.$route.meta.hideSidebar
                ? _c(
                    "el-main",
                    { staticClass: "fun_container" },
                    [_c("router-view", { attrs: { name: "sidebar" } })],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.can_extend && _vm.open_extend,
                expression: "can_extend && open_extend",
              },
            ],
            staticClass: "extend_switch cp un_sel",
            class: { active: _vm.extend_switch },
            on: {
              click: function ($event) {
                _vm.extend_switch = !_vm.extend_switch
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-container",
        { staticClass: "default_container" },
        [
          _c(
            "el-main",
            { staticClass: "default_main d-flex" },
            [
              _c("router-view", { staticClass: "router_view" }),
              1
                ? _c("multitask-fun-bar", {
                    staticClass: "flex-shrink-0 multitask_fun_bar",
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.add_friend_box
          ? _c(
              "div",
              {
                ref: "add_friend_box",
                staticClass: "add_friend_wrapper floor_fixed",
                staticStyle: { "z-index": "2999" },
              },
              [
                _vm.search_friend ? _c("contact-apply-box") : _vm._e(),
                _vm.add_friend
                  ? _c("ContactSendApplycation", {
                      attrs: { result: _vm.add_friend_data },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.add_contact_to_dialog_box
          ? _c(
              "div",
              {
                ref: "add_contact_to_dialog_box",
                staticClass:
                  "layout_default_contact_to_dialog_wrapper floor_fixed",
                on: { click: _vm.closeAddContactToDialogBox },
              },
              [
                _c("CreateDialogContainer", {
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.stopPropagation($event)
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.communication_record_box && 0
          ? _c(
              "div",
              {
                ref: "communication_record_box",
                staticClass: "floor_fixed",
                on: {
                  click: function ($event) {
                    return _vm.$api.common_set_communication_record_box(false)
                  },
                },
              },
              [
                _c("CommunicationRecord", {
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.stopPropagation($event)
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.edit_task_box
          ? _c(
              "div",
              { ref: "edit_task_box", staticClass: "floor_fixed" },
              [_c("Task")],
              1
            )
          : _vm._e(),
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.switch_task_box
          ? _c(
              "div",
              { ref: "switch_task_box", staticClass: "floor_fixed" },
              [_c("Task")],
              1
            )
          : _vm._e(),
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.switch_package_box
          ? _c(
              "div",
              { ref: "switch_package_box", staticClass: "floor_fixed" },
              [
                _c(
                  "div",
                  { staticClass: "create_new_package_wrapper" },
                  [_c("create-new-package")],
                  1
                ),
              ]
            )
          : _vm._e(),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "identify_group",
          attrs: {
            title: "收货地址",
            visible: _vm.identify_group,
            "show-close": false,
            "close-on-click-modal": false,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.identify_group = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "title_group d-flex align-center justify-center",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_c("div", { staticClass: "title" }, [_vm._v("请输入识别码:")])]
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.identify,
                expression: "identify",
              },
            ],
            staticClass: "identify",
            attrs: { type: "text", placeholder: "请输入识别码" },
            domProps: { value: _vm.identify },
            on: {
              keyup: function ($event) {
                _vm.identify_hint = ""
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.identify = $event.target.value
              },
            },
          }),
          _c("div", { staticClass: "hint" }, [
            _vm._v(_vm._s(_vm.identify_hint)),
          ]),
          _c(
            "div",
            {
              staticClass: "footer_group",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "div",
                {
                  staticClass: "btn cp un_sel sure btn_dark",
                  class: { on: _vm.identify != "" },
                  on: { click: _vm.doIdentify },
                },
                [_vm._v("\n        确认\n      ")]
              ),
              _c(
                "div",
                {
                  staticClass: "btn cp cancel btn_light",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.goToLogin()
                    },
                  },
                },
                [_vm._v("\n        取消\n      ")]
              ),
            ]
          ),
        ]
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.switch_annc_selectbox
          ? _c(
              "div",
              { ref: "switch_annc_selectbox", staticClass: "floor_fixed" },
              [_c("contact-selected-container")],
              1
            )
          : _vm._e(),
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.switch_acti_confirm
          ? _c(
              "div",
              { ref: "switch_acti_confirm", staticClass: "floor_fixed" },
              [_c("activation-confirm")],
              1
            )
          : _vm._e(),
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.switch_multiple_contacts
          ? _c(
              "div",
              { ref: "switch_multiple_contacts", staticClass: "floor_fixed" },
              [_c("multiple-contacts")],
              1
            )
          : _vm._e(),
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.switch_share_confirm
          ? _c(
              "div",
              { ref: "switch_share_confirm", staticClass: "floor_fixed" },
              [_c("share-confirm")],
              1
            )
          : _vm._e(),
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.switch_teaching_package
          ? _c(
              "div",
              { ref: "switch_teaching_package", staticClass: "floor_fixed" },
              [_c("teaching-package")],
              1
            )
          : _vm._e(),
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.switch_select_tasks
          ? _c(
              "div",
              { ref: "switch_select_tasks", staticClass: "floor_fixed" },
              [_c("select-tasks")],
              1
            )
          : _vm._e(),
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.switch_popup_common
          ? _c(
              "div",
              { ref: "switch_popup_common", staticClass: "floor_fixed" },
              [_c("pop-up-common")],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "modal",
        { attrs: { name: "taskEdit", height: "90%", width: "80%" } },
        [_c("ModelTask")],
        1
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "globalCoursePackageEdit",
            height: "90%",
            width: "80%",
          },
        },
        [_c("CoursePackegeEdit")],
        1
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "globalCoursePackageChoiceTask",
            height: "90%",
            width: "80%",
          },
        },
        [_c("CoursePackageChoiceTask")],
        1
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "globalCheckCrousePackage",
            height: "90%",
            width: "80%",
          },
        },
        [_c("CheckCoursePackage")],
        1
      ),
      _c(
        "modal",
        {
          attrs: {
            name: "globalDeleteCoursePackage",
            height: "240px",
            width: "340px",
          },
        },
        [
          _c(
            "JsModal",
            {
              attrs: { title: "删除课程" },
              on: {
                closeHandler: function ($event) {
                  return _vm.$modal.hide("globalDeleteCoursePackage")
                },
              },
            },
            [_c("DeleteCoursePackageConfirm")],
            1
          ),
        ],
        1
      ),
      _c(
        "modal",
        {
          ref: "globalCoursePackageFailReason",
          attrs: {
            name: "globalCoursePackageFailReason",
            width: "340px",
            height: "auto",
            clickToClose: false,
          },
          on: {
            "before-open": function ($event) {
              return _vm.beforeOpenModal("globalCoursePackageFailReason")
            },
            opened: function ($event) {
              return _vm.openModal("globalCoursePackageFailReason")
            },
          },
        },
        [
          _c(
            "JsModal",
            {
              attrs: { title: "未通过原因" },
              on: {
                closeHandler: function ($event) {
                  return _vm.$modal.hide("globalCoursePackageFailReason")
                },
              },
            },
            [_c("CrousePackageFailReason")],
            1
          ),
        ],
        1
      ),
      _c(
        "modal",
        {
          ref: "globalAdditionInformation",
          attrs: {
            name: "globalAdditionInformation",
            width: "1000px",
            height: "auto",
            clickToClose: false,
          },
        },
        [_c("AdditionInformation")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }