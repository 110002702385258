<!--
 * @Author       : Hugo
 * @Date         : 2020-05-21 11:32:06
 * @LastEditors  : Hugo
 * @LastEditTime : 2021-07-22 10:03:33
 * @Description  : 拉人到群聊布局
 * @FilePath     : /miaohang/src/components/createDialog/container.vue
-->
<template>
  <div class="create_dialog_container">
    <div class="title_group">
      <div class="title">
        {{ add_contact_dialog_box_type === 'create' ? '添加新联系人' : '选择联系人' }}
      </div>
      <img src="@/assets/images/common/close.png" alt="" class="close cp" @click.stop="closeAddContactToDialogBox">
    </div>
    <div class="search_group">
      <div class="search_box">
        <img src="@/assets/images/common/search_icon.png" alt="" class="search_icon cp">
        <el-input v-model="input" placeholder="搜索" class="search"></el-input>
        <img src="@/assets/images/common/clear_icon.png" alt="" class="clear_icon cp" @click="input = ''">
      </div>
    </div>
    <div class="container_group">
      <div class="select_group">
        <div class="add_new_group padding  cp" @click="goToApplyContact">
          <img src="@/assets/images/common/add_contact_to_dialog.png" alt="" class="img">
          <div class="text">邀请新联系人</div>
        </div>
        <div class="select_all_group padding cp" v-if="0">
          <div class="text">全选</div>
          <div class="icon"></div>
        </div>
        <div
          class="firstname_group"
          v-for="(group_item,group_index) in select_group"
          :key="group_index"
        >
          <div class="title padding"  v-show="!input && getGroupUserLength(group_item)" >{{group_index}}</div>
          <template v-for="(unit_item, unit_index) in group_item" >

            <div
              class="unit padding"
              v-show="((input && unit_item.searchName) || !input)"
              :key="`unit_index${unit_index}`"
            >
            <!-- 拉人 -->
            <template v-if="add_contact_dialog_box_type == ''  ">
              <!-- 单聊页面不展示自己 is_single_dialog &&  unit_item.friendUserId != chat_id-->
              <CreateDialogSelectUnit
                :userData="unit_item"
                :searchName ="unit_item.searchName"
                :on="unit_item.on || false"
                :father_index="`${group_index}`"
                :child_index="`${unit_index}`"
                @doSelect="doSelect"
                v-if="(is_single_dialog &&  unit_item.friendUserId != chat_id) || !is_single_dialog"
              ></CreateDialogSelectUnit>
            </template>
            <!-- 创建 -->
            <template v-else>
              <CreateDialogSelectUnit  :userData="unit_item" :searchName ="unit_item.searchName" :on="unit_item.on || false" :father_index="`${group_index}`" :child_index="`${unit_index}`"  @doSelect="doSelect"></CreateDialogSelectUnit>
            </template>

            </div>
          </template>
        </div>
      </div>
      <div class="selected_group">
        <div class="unit " v-for="(item, index) in selected_group" :key="index">
          <CreateDialogSelectedUnit
            @del_selected="del_selected"
            class="padding"
            v-if="item.userdata.isInChat != 1 || is_single_dialog"
            :selectedData="item"></CreateDialogSelectedUnit>
        </div>
      </div>
    </div>
    <div class="fun_group">
      <button
        class="btn sure"
        @click="doSure"
        v-preventReClick
        :disabled="banned_sure"
      >
        确定
      </button>
      <button
        class="btn cancel"
        @click.stop="closeAddContactToDialogBox"
        v-preventReClick
      >
        取消
      </button>
    </div>
  </div>
</template>
<script>
import CreateDialogSelectUnit from '@/components/createDialog/selectUnit.vue';
import CreateDialogSelectedUnit from '@/components/createDialog/selectedUnit.vue';
export default {
  components:{
    CreateDialogSelectUnit,
    CreateDialogSelectedUnit,
  },
  data(){
    return{
      input:'',
      select_group: null,
      selected_group: [],
      friends_list_group: null,
      userId: this.$tools.getUserId(),
      banned_sure: false, // 禁止确定
    }
  },
  computed: {
    /**
     * 好友分组列表
     */
    // friends_list_group(){
    //   let friends_list_group = this.$tools.deepClone(this.$store.state.friends_list_group);
    //   return friends_list_group;
    // },
    /**
     * 会话id
     */
    chat_id(){
      return this.$store.state.chat_id;
    },
    /**
     * 消息盒子
     */
    message_box_list(){
      return this.$store.state.message_box_list;
    },
    /**
     * 添加联系人到会话容器
     */
    add_contact_to_dialog_box(){
      return this.$store.state.add_contact_to_dialog_box;
    },
    /**
     * 选择联系人类型
     */
    add_contact_dialog_box_type(){
      return this.$store.state.add_contact_dialog_box_type;
    },
    /**
     * 聊天用户列表
     */
    dialog_user_list(){
      return this.$store.state.dialog_user_list;
    },
    /**是否单聊页面 */
    is_single_dialog(){
      let routename = this.$route.name;
      if(routename == 'fun_task_dialog_single'){
        return true;
      }
      return false;
    },

  },
  watch: {
    // friends_list_group: {
    //   handler: function(newval){
    //     if(newval == null){
    //       this.set_friends_list_group();
    //     }
    //     if(newval != null){
    //       // 浅拷贝 todo => 深拷贝
    //       // let newarr = {...newval};
    //       let newarr = this.$tools.deepClone(newval);
    //       this.$set( this, 'select_group', {...newarr});
    //     }
    //   },
    //   deep: true,
    // },
    add_contact_to_dialog_box: function(val){
      if(val){
        // 浅拷贝 todo => 深拷贝
        // let newarr = {...newval};
        let newarr = this.$tools.deepClone(this.friends_list_group);
        this.$set( this, 'select_group', {...newarr});
        this.$set(this, 'selected_group', []);
      }
    },
    '$route.name': function(){},
    chat_id: function(){},
    message_box_list:{
      handler: function(){},
      deep: true,
    },
    add_contact_dialog_box_type: function(){},
    /**搜索 */
    input: function(val){
      val = val.trim();
      let select_group = this.filterContact(val);
      this.$set(this, 'select_group', select_group);
    },
    dialog_user_list: {
      handler: function(){},
      deep: true,
    },
  },
  methods: {
    /**
     * @description: 筛选联系人
     * @param {String} val 搜索字段
     * @return {Object} 选择列表
     */
    filterContact (val) {
      let select_group = this.select_group;
      // 循环用户 如果用户名或昵称匹配得上 新增字段 searchName
      // select_group.forEach( item => {
      if(val){

        for(let item in select_group){
          select_group[item].forEach( (citem,index) => {
            if(citem.userName.toLowerCase().indexOf(val.toLowerCase()) != -1){
              citem.searchName = this.$tools.highlightKeywords(citem.userName, val);
            }else if(citem.friendRemark.toLowerCase().indexOf(val.toLowerCase()) != -1){
              citem.searchName = this.$tools.highlightKeywords(citem.friendRemark, val);
            }else{
              citem.searchName = '';
            }
            this.$set(this.select_group[item][index], 'searchName',  citem.searchName)
          })
        }
      }else{
        for(let item in select_group){
          select_group[item].forEach( (citem,index) => {
            citem.searchName = '';
            this.$set(this.select_group[item][index], 'searchName',  citem.searchName)
          })
        }
      }
      return select_group;
    },
    /**
     * @description: 关闭添加好友弹窗
     * @param {}
     * @return:
     */
    closeAddContactToDialogBox(){
      this.$api.common_set_add_contact_to_dialog_box(false);
    },
    /**
     * @description: 申请添加新联系人
     * @param {type}
     * @return:
     */
    goToApplyContact(){
      this.closeAddContactToDialogBox()
      this.$api.common_open_add_friend_box();
    },
    /**
     * @description: 获取联系人列表
     * @param {type}
     * @return:
     */
    async set_friends_list_group(){
      if(this.friends_list_group == null){
        // await this.$store.dispatch('get_friends_list');
        // console.log(this.chat_id);
        let chat_id = this.chat_id;
        // 创建群聊
        if(this.add_contact_dialog_box_type == 'create'){
          chat_id = 0;
        }
        let res = await this.$http.request('/projectFriends/friendsList', {chatId: chat_id});

        let friends_list= res.contents.list;
        let friends_list_group = this.$tools.packetFriendsList(friends_list);
        this.$set(this, 'friends_list_group', friends_list_group);
        // console.log(friends_list_group)
      }
    },
    /**
     * @description: 选择/取消联系人
     * @param {String} child_index 二级搜索引
     * @param {String} fatcher_index 一级索引
     * @param {Boolean} isInit 是否初始化
     * @return:
     */
    doSelect(father_index, child_index, isInit){
      // && 不是单聊
      if((this.select_group[father_index][child_index].isInChat == 1 && !isInit) && !this.is_single_dialog){
        // 在列表中且不是初始化
        // console.log('无法删除')
      }else{
        let child = this.select_group[father_index][child_index];
        if(child.on){
          //取消
          this.$set(this.select_group[father_index][child_index], 'on', !child.on);
          for(let i in this.selected_group){
            let f = this.selected_group[i].father_index;
            let c = this.selected_group[i].child_index;
            if(f == father_index && c == child_index){
              this.selected_group.splice(i,1); //删除一个
            }
          }
        }else{
          //选中
          this.$set(this.select_group[father_index][child_index], 'on', true);
          let aim = {
            father_index,
            child_index,
            userdata: child,
          }
          this.selected_group.push(aim);
        }
      }
    },
    /**
     * 点击已选中列表删除
     */
    del_selected(father_index, child_index){
      // console.log(father_index, child_index)
      // && 不是单聊
      if((this.select_group[father_index][child_index].isInChat == 1) && !this.is_single_dialog){
        // console.log('无法删除1')
      }else{
        for(let i in this.selected_group){
          let f = this.selected_group[i].father_index;
          let c = this.selected_group[i].child_index;
          if(f == father_index && c == child_index){
            this.selected_group.splice(i,1); //删除一个
          }
        }
        this.$set(this.select_group[father_index][child_index], 'on', false);
      }

    },
    /**
     * 确定
     */
    async doSure(){
      this.banned_sure = true;
      if(this.selected_group.length == 0){
        // this.
        // $confirm('请选择联系人', '提示')
        this.$toast({icon:'none',title:'提示',desc:'请选择联系人',duration:2000});
        this.banned_sure = false;
        return;
      } else{
        let can_do_sure = false;
        let {selected_group, is_single_dialog} = this;
        selected_group.forEach(item => {
          if(item.userdata.isInChat == 0 || is_single_dialog){
            can_do_sure = true;
          }
        })

        if(!can_do_sure){
          this.$api.common_set_add_contact_to_dialog_box(false);
          this.banned_sure = false;
          return;
        }
        if (this.add_contact_dialog_box_type == '') {
          // console.log('拉人到群聊')
          /**默认 拉人到群聊 */
          // 当前用户 当前聊天用户 和已选择用户 创建新聊天
          let to_users = '';
          for(let i in this.selected_group){
            if(i == this.selected_group.length - 1){
              to_users += this.selected_group[i].userdata.friendUserId;
            }else{
              to_users += this.selected_group[i].userdata.friendUserId + ',';
            }
          }
          let data = {
            chat_id: this.chat_id,
            to_users,
          }
          if(this.$route.name == 'fun_task_dialog_single'){
            // 创建群聊
            data.chat_type = 0;
            /** 添加当前登录用户和当前聊天用户 */
            // let current_userId = this.$route.params.id;
            let current_userId = this.$api.moduleTask.getRouteParamsId();
            /**当前登录用户不需要添加 */
            data.to_users += ','+current_userId;
          }else if(this.$route.name == 'fun_task_dialog_group'){
            // 群聊拉人
            data.chat_type = 1;
          }
          let res = await this.$http.request('/project/chatCreate', data)
          this.$api.common_set_add_contact_to_dialog_box(false); // 关闭拉人窗口
          if(data.chat_type == 0){
            this.$store.dispatch('get_workteam_list'); // 刷新工作组列表
            let aim = res.contents.PMessageBox;
            let message_box_list = this.message_box_list
            let index = message_box_list.findIndex(item => {
              return !item.istop;
            })
            if(index != -1){
              message_box_list.splice(index, 0 , aim);
            }else{
              message_box_list.unshift(aim);
            }
            this.$router.push({name:'fun_task_dialog_group', params:{id: this.$tools.btoa(aim.chatId)}});
            this.banned_sure = false;
          }else{
            // 更新当前消息盒子 '当前用户名 邀请 [被添加的用户]'
            let {message_box_list} = this.$store.state;
            let unit = res.contents.PMessageBox;
            let index = message_box_list.findIndex(item=>{
              return item.chatId == unit.chatId && item.chatType == unit.chatType;
            })
            if(index != -1){
              message_box_list.splice(index, 1);
              if(unit.istop){
                // 已经是置顶的聊天 移到顶部
                message_box_list.unshift(unit);
              }else{
                // 不是置顶的 移到非置顶的顶部
                let aim_index = message_box_list.findIndex( item => {
                  return !item.istop;
                })
                if(aim_index != -1){
                  message_box_list.splice( aim_index, 0 ,unit);
                }else{
                  message_box_list.push(unit);
                }
              }
            }
            this.$store.dispatch('get_message_list'); // 刷新消息 (为了获取群内联系人)
            this.banned_sure = false;
          }
        } else if (this.add_contact_dialog_box_type == 'create') {
          /**创建对话 */
          if (this.selected_group.length == 1) {
            // 跳转到单聊 查询是否已有聊天
            let userId = this.selected_group[0].userdata.friendUserId;
            // 查询当前消息盒子列表是否有当前用户的会话 如果没有创建
            let message_box_list = this.$tools.deepClone(this.message_box_list);
            let hasDialog = message_box_list.find((item)=>{
              return item.chatId == userId;
            })
            // 不存在
            if(!hasDialog){
              let message_box_list_unit = {
                masterUserid: this.$tools.getUserId(),
                chatType: 0,
                chatId: userId,
                messageContent: '',
                name: this.selected_group[0].userdata.friendRemark || this.selected_group[0].userdata.userName,
                // pictures: [this.selected_group[0].userdata.thumbnail],
                photo: this.selected_group[0].userdata.thumbnail,
              }
              this.$store.commit('set_message_box_unit',message_box_list_unit );
              // 放在置顶的后面
              let index = message_box_list.findIndex(item => {
                return !item.istop;
              })
              if(index != -1){
                message_box_list.splice(index, 0 , message_box_list_unit);
              }else{
                message_box_list.unshift(message_box_list_unit);
              }
              this.$store.commit('set_message_box_list', message_box_list);
            }else{
              this.$api.common_set_add_contact_to_dialog_box(false); // 关闭拉人窗口
              this.$router.push({name:'fun_task_dialog_single', params:{id: this.$tools.btoa(userId)}})

              return;
            }
            // this.$router.push({name:'fun_task_dialog_single', params:{id: userId}})

            // 跳转到最新的消息盒子(新建群聊)
            this.$nextTick(function(){
              this.$api.common_set_add_contact_to_dialog_box(false); // 关闭拉人窗口
              // console.log(this.message_box_list, 'message_box_list')
              let aim = this.message_box_list[0];
              if(aim.chatType == '1'){
                this.$router.push({name:'fun_task_dialog_group', params:{id: this.$tools.btoa(aim.chatId)}});
              }else if(aim.chatType == '0'){
                this.$router.push({name:'fun_task_dialog_single', params:{id: this.$tools.btoa(aim.chatId)}});
              }
            })
          } else {
            // 群聊
            let to_users = '';
            for(let i in this.selected_group){
              if(i == this.selected_group.length - 1){
                to_users += this.selected_group[i].userdata.friendUserId;
              }else{
                to_users += this.selected_group[i].userdata.friendUserId + ',';
              }
            }
            let data = {
              chat_id: this.chat_id,
              to_users,
            }

              // 创建群聊
            data.chat_type = 0;
            let res = await this.$http.request('/project/chatCreate', data)
            this.$store.dispatch('get_workteam_list') // 刷新工作组列表
            let aim = res.contents.PMessageBox
            let message_box_list = this.message_box_list
            // 放在置顶的后面
            let index = message_box_list.findIndex(item => {
              return !item.istop;
            })
            if(index != -1){
              message_box_list.splice(index, 0 , aim);
            }else{
              message_box_list.unshift(aim);
            }
            this.$api.common_set_add_contact_to_dialog_box(false); // 关闭拉人窗口
            if(aim.chatType == '1'){
              this.$router.push({name:'fun_task_dialog_group', params:{id: this.$tools.btoa(aim.chatId)}});
            }else if(aim.chatType == '0'){
              this.$router.push({name:'fun_task_dialog_single', params:{id: this.$tools.btoa(aim.chatId)}});
            }
          }
        }
      }
    },
    /**初始加载 */
    init(){
      /**
       * 拉人到群聊的时候选中联系人
       *
       * 当前版本不应该被删除
       *  */
      if(!this.is_single_dialog && this.add_contact_dialog_box_type == ''){
        let select_group = this.select_group;
        for(let i in select_group){
          select_group[i].forEach((father_item, father_index)=>{
            // 初始选中 遍历联系人列表是否在会话联系人中 获取索引
            /**该联系人不可删除 todo */
            if(father_item.isInChat == 1){
              this.doSelect(i, father_index, true);
            }
          })
        }
      }
    },
    /**
     * @description: 获取可展示的用户长度[单聊]
     * @param {Array} 单个字母开头的用户数组
     * @return {*} [单聊] 实际现实的数量 / [群聊] 1
     */
    getGroupUserLength(list){
      const {is_single_dialog} = this;
      if(is_single_dialog){
        let length = 0;
        // const userid = this.$tools.getUserId();
        const filter_list = list.filter((item)=> {
          return item.friendUserId != this.chat_id;
        })
        length = filter_list.length;
        return length;
      }else{
        return 1;
      }

    },
  },
  async mounted(){
    await this.set_friends_list_group()
    this.$set(
      this,
      'select_group',
      {
        ...this.friends_list_group
    })
    await this.init()
    // console.log(this.select_group);
  }

}
</script>
<style lang="scss" scoped>
.create_dialog_container{
  @include bbox;
  @include flex;
  flex-direction: column;
  width:602px;
  height:768px;
  max-height: 80%;
  background:rgba(255,255,255,1);
  box-shadow:0px 0px 40px 0px rgba(0, 0, 0, 0.16);
  border-radius:4px;
  overflow: hidden;
  .title_group{
    @include bbox;
    flex-shrink: 0;
    text-align: center;
    width:100%;
    position: relative;
    z-index: 2;
    padding-top:36px;
    .title{
      font-size:18px;

      font-weight:400;
      color:rgba(51,51,51,1);
      position: relative;
      z-index: 2;
    }
    .close{
      position: absolute;
      z-index: 3;
      top: 42px;
      right: 30px;
      width: 11px;
      height: 11px;
    }
  }
  .search_group{
    flex-shrink: 0;
    padding: {
      top:39px;
      bottom: 30px;
    }
    .search_box{
      @include flexsb;
      @include bbox;
      padding: {
        left: 30px;
        right: 20px;
      }
      margin:0 auto;
      width:400px;
      height:40px;
      background:rgba(243,243,243,1);
      border-radius:20px;
      .search_icon{}
      .search{
        border:none;
        background-color: transparent;
        .el-input__inner{
          border:none;
          background-color: transparent;
        }
      }
      .clear_icon{}
    }
  }
  .container_group{
    @include bbox;
    @include flex;
    align-items: stretch;
    height:100%;
    max-height: 100%;
    flex:1;
    width:100%;
    overflow: hidden;
    border: {
      top: 2px solid #f0f0f0;
      bottom: 2px solid #f0f0f0;
    }
    .padding{
      padding: {
        top: 10px;
        bottom: 10px;
        left:37px;
        right:46px;
      }
    }
    .select_group{
      @include bbox;
      @include hidescrollbar;
      padding: 20px 0;
      overflow-y: auto;
      width:50%;
      border-right: 1px solid #f0f0f0;
      .add_new_group{
        @include flex;
        padding-top: 10px;
        .img{
          width: 44px;
          height: 44px;
          border-radius: 4px;
          display: block;
        }
        .text{
          padding-left: 16px;
          font-size:14px;

          font-weight:400;
          color:rgba(51,51,51,1);
        }
      }
      .select_all_group{
        @include bbox;
        @include flexsb;
        @include transition;
        // background-color: #fff;
        padding: {
          left: 37px;
          top: 20px;
          bottom: 20px;
        }
        width:100%;
        &.on{
          .icon{
            border:none;
            background-image: url(~@/assets/images/create_dialog/selected.png);
            background-size: 100%;
          }
        }
        .text{
          @include bbox;
          width:100%;
          flex:1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size:14px;

          font-weight:400;
          color:rgba(51,51,51,1);
        }
        .icon{
          @include bbox;
          width:18px;
          height:18px;
      // @include transition;
          border:2px solid rgba(153,153,153,1);
          border-radius:50%;
        }
      }
      .firstname_group{
        .title{
          @include bbox;
          font-size:14px;

          font-weight:400;
          color:rgba(153,153,153,1);
          text-transform: uppercase;
        }
        .unit{
          @include bbox;
          @include transition;
          background-color:#fff;
          &:hover{
            background-color: $background_hover_color;
          }
        }
      }
    }
    .selected_group{
      @include bbox;
      @include hidescrollbar;
      flex-shrink: 0;
      padding: {
        top: 20px;
        bottom: 20px;
      }
      overflow-y: auto;
      width:50%;
      border-left: 1px solid #f0f0f0;
      .unit{
        @include bbox;
        @include transition;
        background-color:#fff;
        &:hover{
          background-color: $background_hover_color;
        }
      }
    }
  }
  .fun_group{
    @include flex;
    padding:20px 0;
    flex-shrink: 0;
    .btn{
      @include transition;
      width:160px;
      line-height:50px;
      background:rgba(204,204,204,1);
      border-radius:4px;
      margin: 0 20px;
      font-size:18px;
      outline: none;
      border: none;
      font-weight:400;
      text-align: center;
      color:#fff;
      cursor: pointer;
    }
    .sure{
      background-color: $main_blue;
      &:hover{
        background-color: #004080;
      }
    }
    .cancel{
      background-color:rgba(0,84,166,0.1);
      color: $main_blue;
      &:hover{
        background-color: rgba(0,84,166,0.2);
      }
    }
  }
}
@media screen and (max-height: 800px){
.create_dialog_container{
  .title_group{
    padding-top: 16px;
    .title{
      font-size: 14px;
    }
    .close{
      top:22px;
    }
  }
  .search_group{
    padding: {
      top: 10px;
      bottom: 10px;
    }
    .search_box{
      padding: {
        left: 20px;
        right: 10px;
      }
      width:300px;
      height:30px;
      border-radius:15px;
    }
  }
  .container_group{
    .select_group{
      padding: 10px 0;
      .add_new_group{
        padding-top: 6px;
        .img{
          width: 30px;
          height: 30px;
        }
      }
      .select_all_group{
        padding: {
          left: 37px;
          top: 10px;
          bottom: 10px;
        }
        width:100%;
        &.on{
          .icon{
            border:none;
            background-image: url(~@/assets/images/create_dialog/selected.png);
            background-size: 100%;
          }
        }
      }
    }
    .selected_group{
      padding: {
        top: 10px;
        bottom: 10px;
      }
    }
  }
  .fun_group{
    padding:10px 0;
    flex-shrink: 0;
    .btn{
      width:130px;
      line-height:30px;
      margin: 0 10px;
      font-size:12px;
    }
  }
}
}
</style>
<style lang="css">
  .create_dialog_container .search .el-input__inner{
    border:none;
    background-color: transparent;
    font-size:14px;

    font-weight:400;
    color:#666;
    padding: 0 10px;
  }
</style>
